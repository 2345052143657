import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { createAnuncio } from '../../../api/admin/anuncios';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { optionsToast } from '../../../config/toast';
import { useCustomState } from '../../../hooks/useCustomState';
import { getStatusActive } from '../../../misc/getStatus';
import { getVisibilities } from '../../../misc/getVisibilities';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { isEmpty } from '../../../utils';
const prefix = "/admin/anuncios";

const CrearAnuncio = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    text: "",
    link: "",
    estado_seleccionado: null,
    listado_estados: [],
    visibilidad_seleccionada: null,
    listado_secciones: [],
  });

  useEffect(() => {
    loadData();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    setState({
      listado_estados: await getStatusActive(),
      listado_secciones: await getVisibilities(),
      loading: false,
    });
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onCreate = useCallback(async () => {
    if (isEmpty(state.text)) {
      return message(null, 'El anuncio es obligatorio.');
    }
    if (isEmpty(state.visibilidad_seleccionada)) {
      return message(null, 'Selecciona el tipo de visibilidad para continuar.');
    }

    const paramsFields = {
      text: state.text,
      link: state.link,
      visibility: state.visibilidad_seleccionada,
      status: state.estado_seleccionado,
    };

    try {
      setState({ sending: true });

      const response = await createAnuncio(paramsFields);

      setState({ sending: false });

      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Anuncio creado', '¡El anuncio se ha creado exitosamente! Serás redirigido a la página de anuncios en breve...');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el anuncio. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el anuncio. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state, setState, navigate]);

  return (
    <>
      <SEO
        title="Crear anuncio"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Crear anuncio</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg max-w-md'>
              <div className='flex flex-col gap-4'>
                <input
                  type="text"
                  placeholder='Anuncio'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.text}
                  onChange={(event) => setState({ text: event.target.value })}
                />
                <input
                  type="url"
                  placeholder='URL'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.link}
                  onChange={(event) => setState({ link: event.target.value })}
                />
                <span className="flex-1">
                  <Dropdown
                    value={state?.visibilidad_seleccionada}
                    onChange={(event) => setState({ visibilidad_seleccionada: event.target.value })}
                    options={state.listado_secciones}
                    optionLabel="name"
                    placeholder="Seleccionar visibilidad"
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  />
                </span>
                <Dropdown
                  value={state?.estado_seleccionado}
                  onChange={(event) => setState({ estado_seleccionado: event.target.value })}
                  options={state.listado_estados}
                  optionLabel="name"
                  placeholder="Estado"
                  emptyMessage="No se encontraron resultados"
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                />
              </div>
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text="CANCELAR"
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                />
                <ButtonCustom
                  text="CREAR"
                  classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                  onClick={onCreate}
                  loading={state.sending}
                />
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default CrearAnuncio;