import React, { useCallback, useEffect } from 'react';
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux';
import { ReactComponent as IconoDownload } from "../../assets/icons/download-icon.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import { getDescargables } from '../../api/client/descargables';
import { optionsToast } from '../../config/toast';
import { useCustomState } from '../../hooks/useCustomState';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { isEmpty } from '../../utils';

const Descargables = () => {
  const user = useSelector(state => state?.app?.auth?.user);
  const [state, setState] = useCustomState({
    loading: true,
    descargables: [],
  });

  useEffect(() => {
    if (!isEmpty(user?.programID)) loadData(user?.programID);

    // eslint-disable-next-line
  }, [user?.programID]);

  const loadData = useCallback(async (programID) => {
    try {
      setState({ loading: true });
      const _descargables = await getDescargables({
        programas: [programID],
      });

      setState({
        descargables: _descargables?.data,
        loading: false,
      });
    } catch (error) {
      setState({ descargables: [], loading: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  return (
    <>
      <SEO
        title="Descargables"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            <div className='flex flex-col gap-4'>
              {(user?.subscription?.isActive !== true) ? (
                <div className='flex flex-col items-center justify-center gap-6'>
                  <div className='bg-white px-4 py-6 rounded-xl w-full'>
                    <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
                    <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                  <div className='flex flex-col text-center md:text-left'>
                    <p className='font-golos-semibold text-Crayola text-2xl'>Descargables</p>
                    <p>Usa los siguientes documentos para complementar tu programa.</p>
                  </div>
                  {state.loading ? (
                    <Loading />
                  ) : (state.descargables?.length === 0) ? (
                    <p className='font-golos-semibold text-red-600 text-center text-lg'>No se encontraron resultados</p>
                  ) : (
                    <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-6'>
                      {state.descargables?.length > 0 && state.descargables?.map((item, index) => (
                        <div key={`descargable-key-${item?.id || index}`} className="flex flex-col">
                          <Image
                            src={item?.image}
                            imageClassName="w-full object-cover object-top h-full rounded-t-xl pt-6 px-6"
                            className='bg-Magnolia border border-gray-100 min-h-[160px] h-[160px] rounded-t-xl'
                            onError={(event) => {
                              event.target.onerror = null; // previene un bucle infinito
                              event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                            }}
                          />
                          <div className='bg-Crayola pl-6 py-3 flex items-center space-x-2 -mt-[2px] rounded-b-xl'>
                            <div className='flex-1'>
                              <p
                                className='font-golos-semibold text-white'
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.name}
                              </p>
                              <p className='text-white text-sm mb-1'>{item?.description}</p>
                            </div>
                            <div className='flex justify-center items-center h-full'>
                              <a href={item?.file} className='inline-flex c-pointer py-3 pl-3 pr-4' title="Descargar" rel="noreferrer" target='_blank' download>
                                <IconoDownload fill={"#fff"} width={16} />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Descargables;