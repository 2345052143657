import QueryString from "qs";
import axios from "../";
const prefix = "/admin/anuncios";

export const getAnuncios = async (params) => {
  try {
    const _params = QueryString.stringify(params);
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAnuncio = async (params) => {
  try {
    const response = await axios.post(`${prefix}/nuevo-anuncio`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAnuncio = async (id, params) => {
  try {
    const response = await axios.put(`${prefix}/${id}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAnuncio = async (id) => {
  try {
    const response = await axios.delete(`${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};