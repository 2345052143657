import React, { memo, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { usePromocion } from "./PromocionProvider";

const Promocion = ({ initialMode = "external" }) => {
  const { state, setMode } = usePromocion();

  // eslint-disable-next-line
  useEffect(() => setMode(initialMode), [initialMode]);

  if (!state.isActive) return null;

  return (
    <div className="animate__animated animate__faster animate__slideInDown flex items-center justify-center bg-Orchid text-DarkJungleGreen py-4 w-full">
      <Marquee
        autoFill={true}
        delay={5}
        pauseOnHover={true}
        pauseOnClick={true}
        speed={30}
      >
        {state.data?.map((item, index) => {
          const commonProps = {
            key: `promocion-text-key-${item?.id || index}`,
            className: "block font-golos-medium font-medium mb-0 text-center text-white mx-4",
            style: { fontSize: "0.930em" },
          };

          return item.link ? (
            <a {...commonProps} href={item.link} className={`${commonProps.className} !font-bold underline`} rel="noreferrer" target="_blank">
              {item.text}
            </a>
          ) : (
            <p {...commonProps}>{item.text}</p>
          );
        })}
      </Marquee>
    </div>
  );
};

export default memo(Promocion);