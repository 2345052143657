export const getVisibilities = async () => {
  return [
    {
      name: "Interno",
      value: "internal"
    },
    {
      name: "Externo",
      value: "external"
    },
    {
      name: "Ambos",
      value: "both"
    },
  ];
}