import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { getAnuncios, deleteAnuncio } from '../../../api/admin/anuncios';
import SEO from '../../../components/SEO';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import { paginationComponentOptions, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
const prefix = "/admin/anuncios";

const Anuncios = () => {
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    anuncios: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    try {
      setState({ loading: true });
      const anuncios = await getAnuncios();
      setState({
        anuncios: anuncios?.data,
        loading: false,
      });
    } catch (error) {
      console.log("🚀 ~ loadData ~ error:", error)
    }
  }, [setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    // eslint-disable-next-line
    if (type === "ver") {
      if (params?.id) navigate(`${prefix}/${params?.id}`);
    } else if (type === "editar") {
      if (params?.id) navigate(`${prefix}/editar-anuncio/${params?.id}`);
    } else if (type === "eliminar") {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <div className='text-center'>
            <p>¿Estás segura?</p>
            <p>Esta acción no se puede deshacer.</p>
          </div>
        ),
        icon: null,
        rejectClassName: "bg-neutral-400 border-neutral-400 hover:!bg-neutral-500 hover:!border-neutral-500 !py-1",
        acceptClassName: 'p-button-danger !py-1',
        acceptLabel: "Si, eliminar",
        accept: async () => {
          if (params?.id) await deleteAnuncio(params?.id);
          loadData();
        },
      });
    }
  }, [loadData, navigate]);

  const { columnsTable } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Texto',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.text),
        cell: row => (
          <div className='py-2'>{row.text}</div>
        )
      },
      {
        name: 'Enlace',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.link),
        cell: row => (
          <div className='py-2'>{row.link}</div>
        )
      },
      {
        name: 'Visibilidad',
        sortable: true,
        fixed: 'left',
        selector: row => {
          if (row.visibility === "internal") {
            return "Interno";
          } else if (row.visibility === "external") {
            return "Externo";
          } else if (row.visibility === "both") {
            return "Ambos";
          }
          return "";
        },
        cell: row => {
          if (row.visibility === "internal") {
            return <div className='py-2'>Interno</div>;
          } else if (row.visibility === "external") {
            return <div className='py-2'>Externo</div>;
          } else if (row.visibility === "both") {
            return <div className='py-2'>Interno y Externo</div>;
          }
          return "";
        },
      },
      {
        name: 'Estado',
        sortable: true,
        fixed: 'left',
        width: '120px',
        selector: row => {
          if (row.status === "active") {
            return "Activo";
          } else if (row.status === "inactive") {
            return "Inactivo";
          }
          return "";
        },
        cell: row => {
          if (row.status === "active") {
            return <span className='bg-green-600 text-white rounded-lg py-2 px-4'>Activo</span>;
          } else if (row.status === "inactive") {
            return <span className='bg-gray-500 text-white rounded-lg py-2 px-4'>Inactivo</span>;
          }
          return "";
        },
      },
      {
        name: 'Acciones',
        center: true,
        width: '260px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-green-600 hover:bg-green-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "ver")}>Ver</button>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
            <button type="button" className='bg-red-600 hover:bg-red-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "eliminar")}>Eliminar</button>
          </div>
        ),
      },
    ],
  }), [onActionTable]);

  const filteredItems = state.anuncios;

  return (
    <>
      <SEO
        title="Anuncios"
      />
      <ConfirmPopup />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Anuncios</h1>
                <div className='inline-flex flex-wrap justify-center lg:justify-start gap-3'>
                  <ButtonCustom
                    text="Crear anuncio"
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={() => navigate(`${prefix}/crear-anuncio`)}
                  />
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader={false}
                  selectableRows={false}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Anuncios;