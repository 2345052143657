import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
// import { ReactComponent as Icon03 } from "../../assets/icons/programas/icon03.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import { getMisRecibos } from '../../api/client/mis_recibos';
import { useCustomState } from '../../hooks/useCustomState';
import { getNumericZero } from '../../utils';
import { dayjs_custom } from '../../utils/dayjs_custom';

require('dayjs/locale/es');
dayjs.locale("es");
dayjs.extend(isBetween);

addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar'
});

const MisRecibos = () => {
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    mis_recibos: [],
    dates: null,
  });

  const { filter_mis_recibos } = useMemo(() => ({
    filter_mis_recibos: state.mis_recibos
  }), [state.mis_recibos]);

  useEffect(() => {
    getMisRecibos({
      createdAt: (state?.dates) && state.dates,
      userID: user.userID,
    }).then(response => {
      setState({ loading: false, mis_recibos: response?.data?.reverse() });
    });

    // eslint-disable-next-line
  }, [user.userID, state.dates]);

  return (
    <>
      <SEO
        title="Mis Recibos"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                <div className='flex items-center justify-center flex-wrap gap-6'>
                  <div className='flex-1 flex flex-col text-center md:text-left min-w-[220px] mx-auto'>
                    <p className='font-golos-semibold text-Crayola text-2xl'>Mis Recibos</p>
                    <p>A continuación se enlistan los recibos de compra que has realizado.</p>
                  </div>
                  <div className='flex items-center justify-center'>
                    <div className='flex items-center gap-2'>
                      <Calendar
                        dateFormat="M dd, yy"
                        value={state.dates}
                        onChange={(e) => setState({ dates: e.value })}
                        selectionMode="range"
                        readOnlyInput
                        locale="es"
                        className='bg-Crayola w-full'
                        inputClassName='bg-Magnolia border border-gris-F7F7F7 text-sm focus:!border-gris-F7F7F7 focus:!shadow-none focus:ring-0 outline-none !cursor-pointer capitalize w-full sm:min-w-[220px]'
                        placeholder='Filtrar por fecha'
                        showIcon
                      />
                    </div>
                  </div>
                </div>
                {state.loading ? (
                  <Loading />
                ) : (filter_mis_recibos?.length === 0) ? (
                  <p className='font-golos-semibold text-red-600 text-center text-lg'>No se encontraron resultados.</p>
                ) : (
                  <div className='flex flex-col space-y-4'>
                    {filter_mis_recibos?.length > 0 && filter_mis_recibos?.map((item, index) => (
                      <div key={`recibo-key-${item?.id || index}`} className="bg-Magnolia grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 items-center rounded-xl px-5 py-6">
                        <div className='mx-auto lg:m-none text-center lg:text-left w-full'>
                          <p className='font-golos-medium text-md leading-5'># {item?.invoiceCode}</p>
                          <p className='capitalize text-sm'>{dayjs_custom(item?.createdAt).format("MMMM DD, YYYY")}</p>
                        </div>
                        <div className='mx-auto text-center lg:text-left w-full'>
                          <p className='font-golos-medium text-md leading-5'>Entrenamiento:</p>
                          <p className='capitalize text-sm'>{item?.isInternal ? `${item?.Program?.name}` : `${item?.Program?.name} − ${item?.typePlan}`} {item?.isTrial === true ? "(Prueba 7 días)" : ""}</p>
                        </div>
                        {item?.isInternal ? (
                          <div className='mx-auto text-center lg:text-left break-words w-full'>
                            <p className='font-golos-semibold text-md leading-5'>Administrado por</p>
                            <p className='lg:truncate text-sm'>JimeFitMom</p>
                          </div>
                        ) : (
                          <div className='mx-auto text-center lg:text-left break-words w-full'>
                            <p className='font-golos-medium text-md leading-5'>{item?.paymentType === "Stripe" ? <span className="uppercase">{item?.cardType} {item?.cardLastNumber}</span> : "Vía PayPal"}</p>
                            <p className='hyphens-none text-sm'>{item?.paymentType === "Stripe" ? `Exp ${getNumericZero(item?.expirationMonth)} / ${item?.expirationYear}` : (item?.paypalSubscriberID || item?.User?.Client?.email)}</p>
                          </div>
                        )}
                        <div className='text-center lg:text-left w-full'>
                          <p className='font-golos-medium text-md leading-5'>Monto pagado:</p>
                          <p className='capitalize text-sm'>${parseFloat(item?.initialPayment)?.toFixed(2)}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default MisRecibos;