import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ContextProviderCompound from './contexts';
import { PromocionProvider } from './components/PromocionProvider';
import { persistor, store } from './redux/store/index';
import RootRouter from './router/RootRouter';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PromocionProvider>
          <ContextProviderCompound>
            <RootRouter />
          </ContextProviderCompound>
        </PromocionProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;