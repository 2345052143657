/**
 * Obtiene los elementos del carrusel de programas.
 * 
 * Esta función devuelve un array de objetos que representan los elementos
 * del carrusel para mostrar información sobre programas específicos.
 * 
 * @returns {Array<Object>} Lista de elementos del carrusel.
 * @property {string} title - Título principal del elemento.
 * @property {string} subtitle - Subtítulo del elemento.
 * @property {string} description - Descripción breve del contenido.
 * @property {string} image - Ruta de la imagen asociada al elemento (utiliza `require`).
 * @property {string} buttonText - Texto del botón asociado al elemento.
 * @property {string} classNameBg - Clases adicionales para personalizar el fondo del elemento.
 * @property {boolean} url_external - Indica si la URL es externa.
 * @property {boolean} url_anchor - Indica si la URL es un ancla en la página.
 * @property {string} url - URL asociada al elemento.
 * @property {boolean} [hideButton] - (Opcional) Oculta el botón si está presente.
 * @property {boolean} [hideTexts] - (Opcional) Indica si los textos (título, subtítulo y descripción) deben ser ocultados.
 * 
 * @example
 * const items = getCarouselProgramasItems();
 * console.log(items[0].title); // "Programas de Ejercicios"
 */
export const getCarouselProgramasItems = () => {
  return [
    {
      title: "Programas de Ejercicios",
      subtitle: "para Embarazo y Posparto",
      description: "Entrena de la mano de una Fitness Coach Pre y Posnatal",
      image: require("../assets/images/home/banners/banner-navidad.jpg"),
      buttonText: "¡Comienza ahora!",
      classNameBg: "!bg-[83%_center] sm:!bg-[100%_center] lg:!pb-[8rem]",
      url_external: false,
      url_anchor: true,
      url: "/#programas-de-entrenamiento",
      hideTexts: false,
      hideButton: false,
    },
    {
      title: "CLUB VIP de",
      subtitle: "JIMEFITMOM",
      description: "Recibe ofertas exclusivas y encuentra recursos gratuitos para tu preparación",
      image: require("../assets/images/home/02-headerimg.jpg"),
      buttonText: "¡ÚNETE AL CLUB!",
      classNameBg: "",
      url_external: true,
      url_anchor: false,
      url: "https://jimefitmom.vip",
      // hideButton: true,
    },
  ]
}

export const getCarouselTestimoniosEmbarazoItems = () => {
  return [
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_0340.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_0344.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7393.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7394.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7395.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7396.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7397.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7399.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7400.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7402.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7404.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7407.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/embarazo/IMG_7410.jpg"),
    },
  ]
}

export const getCarouselTestimoniosPospartoItems = () => {
  return [
    {
      description_img: "/assets/images/testimonios/posparto/1.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/2.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/3.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/4.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/5.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/6.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/7.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/8.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/9.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/11.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/12.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/13.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/14.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/16.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/17.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/18.png",
    },
    {
      description_img: "/assets/images/testimonios/posparto/19.png",
    },
  ]
}

export const getCarouselTestimoniosStrengthItems = () => {
  return [
    {
      description_img: require("../assets/images/resenias/strength/IMG_0346.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/strength/IMG_0347.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/strength/IMG_0348.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/strength/IMG_0349.jpg"),
    },
    {
      description_img: require("../assets/images/resenias/strength/IMG_0350.jpg"),
    },
  ]
}

export const getCarouselProductosRecomendadosItems = async () => {
  return [
    {
      title: "Liga de Resistencia para Ejercicio, Bandas de Resistencia para Ejercicio en Casa o Gimnasio, Ligas para Hacer dominadas Pull Up. Liga de Entrenamiento",
      image: require("../assets/images/productos/prod01.jpg"),
      url: "/"
    },
    {
      title: "Dispositivo suelo pélvico, Entrenador de cadera posparto, Equipo de dieta, Adelgazante de muslos, Levantador de cadera, Artículos para entrenamiento muscular",
      image: require("../assets/images/productos/prod02.jpg"),
      url: "/"
    },
    {
      title: "Liga de Resistencia para Ejercicio, Bandas de Resistencia para Ejercicio en Casa o Gimnasio, Ligas para Hacer dominadas Pull Up. Liga de Entrenamiento",
      image: require("../assets/images/productos/prod01.jpg"),
      url: "/"
    },
    {
      title: "Dispositivo suelo pélvico, Entrenador de cadera posparto, Equipo de dieta, Adelgazante de muslos, Levantador de cadera, Artículos para entrenamiento muscular",
      image: require("../assets/images/productos/prod02.jpg"),
      url: "/"
    },
    {
      title: "Liga de Resistencia para Ejercicio, Bandas de Resistencia para Ejercicio en Casa o Gimnasio, Ligas para Hacer dominadas Pull Up. Liga de Entrenamiento",
      image: require("../assets/images/productos/prod01.jpg"),
      url: "/"
    },
    {
      title: "Dispositivo suelo pélvico, Entrenador de cadera posparto, Equipo de dieta, Adelgazante de muslos, Levantador de cadera, Artículos para entrenamiento muscular",
      image: require("../assets/images/productos/prod02.jpg"),
      url: "/"
    },
  ]
}

export const getCarouselVideosSugeridosItems = async () => {
  return [
    {
      title: "Lorem ipsum dolor sit",
      image: require("../assets/images/workouts/img-workout-ejemplo1.jpg"),
      video: 'https://vimeo.com/150721546',
    },
    {
      title: "Lorem ipsum dolor sit",
      image: require("../assets/images/workouts/img-workout-ejemplo1.jpg"),
      video: 'https://vimeo.com/179023166',
    },
    {
      title: "Lorem ipsum dolor sit",
      image: require("../assets/images/workouts/img-workout-ejemplo1.jpg"),
      video: 'https://vimeo.com/326760644',
    },
    {
      title: "Lorem ipsum dolor sit",
      image: require("../assets/images/workouts/img-workout-ejemplo1.jpg"),
      video: 'https://vimeo.com/760376484',
    },
    {
      title: "Lorem ipsum dolor sit",
      image: require("../assets/images/workouts/img-workout-ejemplo1.jpg"),
      video: 'https://vimeo.com/760376484',
    },
    {
      title: "Lorem ipsum dolor sit",
      image: require("../assets/images/workouts/img-workout-ejemplo1.jpg"),
      video: 'https://vimeo.com/760376484',
    },
  ]
}