import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import { getFavorites } from '../../api/client/favorites';
import { optionsToast } from '../../config/toast';
import { useCustomState } from '../../hooks/useCustomState';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { obtenerDuracionFormateada } from '../../utils/parseDuration';

const MisFavoritos = () => {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    favorites: [],
  });

  useEffect(() => {
    loadData(user?.userID);

    // eslint-disable-next-line
  }, [user?.userID]);

  const loadData = useCallback(async (userID) => {
    try {
      setState({ loading: true });
      const _favorite = await getFavorites({
        userID: userID
      });

      setState({
        favorites: _favorite?.data,
        loading: false,
      });
    } catch (error) {
      setState({ favorites: [], loading: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const { formatDuration } = useMemo(() => {
    const formatDuration = (_workout) => obtenerDuracionFormateada(_workout?.durationVideo);

    return { formatDuration };
  }, []);

  return (
    <>
      <SEO
        title={"Mis favoritos"}
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />

      <div className='bg-Magnolia h-full pb-4'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            <div className='flex flex-col gap-4'>
              {(user?.subscription?.isActive !== true) ? (
                <div className='flex flex-col items-center justify-center gap-6'>
                  <div className='bg-white px-4 py-6 rounded-xl w-full'>
                    <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
                    <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                  {state.loading ? (
                    <Loading />
                  ) : (state.favorites?.length === 0) ? (
                    <div className='flex flex-col gap-6 justify-center'>
                      <p className='font-golos-semibold text-red-600 text-center text-lg'>No se encontraron resultados.</p>
                    </div>
                  ) : (
                    <div className='space-y-8'>
                      <div className='flex flex-col text-center md:text-left'>
                        <p className='font-golos-semibold text-Crayola text-2xl'>Mis favoritos</p>
                      </div>
                      <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-6'>
                        {state.favorites?.length > 0 && state.favorites?.map((item, index) => {
                          let video;

                          if (item?.workoutID) {
                            video = item?.Workout;
                          } else if (item?.coreID) {
                            video = item?.Core;
                          } else if (item?.galeriaVideoID) {
                            video = item?.GaleriaVideo;
                            video.type = item?.GaleriaVideo?.type || "parto";
                          }

                          return (
                            <div key={`favorite-key-${item?.id || index}`} className="flex flex-col space-y-2 overflow-hidden">
                              <div className='group relative rounded-xl overflow-hidden'>
                                <div className="absolute inset-0 top-0 left-0 bg-black bg-opacity-0 opacity-0 group-hover:bg-opacity-30 group-hover:opacity-100 rounded-2xl transition duration-300" />
                                <div
                                  className="absolute inset-0 flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition duration-300"
                                  onClick={() => video?.id && navigate(`${video?.type}/clase-${video?.id}`)}
                                >
                                  <div className="bg-black bg-opacity-50 p-2 rounded-full">
                                    <svg className="w-10 h-10 text-white" fill="currentColor" viewBox="0 0 20 20">
                                      <path d="M6 4l8 6-8 6V4z"></path>
                                    </svg>
                                  </div>
                                </div>
                                <Image
                                  src={video?.image}
                                  imageClassName="w-full object-cover h-full rounded-xl"
                                  className='flex bg-white border border-gray-100 min-h-[160px] h-[160px] rounded-xl'
                                  onError={(event) => {
                                    event.target.onerror = null; // previene un bucle infinito
                                    event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                                  }}
                                />
                                <div className='absolute bottom-0 right-0 pointer-events-none'>
                                  <p className='mr-2 mb-2 bg-black/50 text-white text-muted px-2 py-1 rounded-md select-none'>
                                    {`${formatDuration(video)?.minutos || "00"}:${formatDuration(video)?.segundos || "00"}`}
                                  </p>
                                </div>
                              </div>
                              <div className='flex flex-col sm:flex-row gap-2 justify-center items-center'>
                                <div className='flex-1 text-center sm:text-left'>
                                  <p className='ml-3 font-golos-medium text-Crayola text-lg'>{video?.title}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default React.memo(MisFavoritos);