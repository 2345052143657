import React, {
  createContext,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { getAnuncios } from "../api/client/anuncios";
import { useCustomState } from "../hooks/useCustomState";

const PromocionContext = createContext(null);

const usePromocion = () => {
  const context = useContext(PromocionContext);
  if (!context) {
    throw new Error(
      "usePromocion debe ser usado dentro de un PromocionProvider"
    );
  }
  return context;
};

const PromocionProvider = ({ children, initialMode }) => {
  const [state, setState] = useCustomState({
    isActive: false,
    data: [],
  });
  const [mode, setMode] = useState(initialMode);

  const loadData = useCallback(async () => {
    if (mode && ["internal", "external"].includes(mode)) {
      try {
        setState({ isActive: false });
        const response = await getAnuncios({ visibility: mode });

        if (response?.status === true && response?.data?.length > 0) {
          setState({
            isActive: true,
            data: response?.data,
          });
        } else {
          setState({ isActive: false });
        }
      } catch (error) {
        setState({ isActive: false });
      }
    }
  }, [mode, setState]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <PromocionContext.Provider
      value={{
        state,
        mode,
        setMode
      }}
    >
      {children}
    </PromocionContext.Provider>
  );
};

export { PromocionProvider, PromocionContext, usePromocion };